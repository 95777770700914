<template>
<Wrapper>
  <div class="service-article">
    <DetailBanner title=""></DetailBanner>
    <div class="article w">
      <div class="hd">
        <div class="time">
          <img src="@/assets/imgs/time.png" />
          <p>发布于{{detail.createDate}}&nbsp;&nbsp;|</p>
          <p>&nbsp;&nbsp;浏览量:{{totalBrowseNum}}</p>
        </div>
        <div class="goback" @click="goback">
          <p>返回指数点评列表</p>
          <img src="@/assets/imgs/detail-back.png" />
        </div>
      </div>
      <div class="bd">
        <h2>{{detail.title}}</h2>
        <div class="body_html" v-html="detail.content"/>
      </div>
      <div class="ft">
        <!-- <div class="w prev">
          <img src="@/assets/imgs/prev.png" />
          <p>上一篇：踢踢踢踢腿提提他</p>
        </div>
        <div class="hr"></div>
        <div class="w next">
          <img src="@/assets/imgs/prev.png" />
          <p>下一篇：踢踢踢踢腿提提他</p>
        </div> -->
      </div>
    </div>
  </div>
  </Wrapper>
</template>

<script>
import DetailBanner from '../sections/DetailBanner.vue'
import Wrapper from '../../components/Wrapper.vue'

export default {
  mixins: [],
  components: {
    DetailBanner,
    Wrapper
  },
  data () {
    return {
      id:null,
      detail:{},
      totalBrowseNum:0
    }
  },
  created () {
    this.id = this.$route.params.id
    this.$axios.toYshNews3Detail(this.id).then( res => {
      this.detail = res.data || {}
    })
    this.$axios.getYshNews3ViewCount(this.id).then( res => {
      this.totalBrowseNum = res.data || 0
    })
  },
  methods: {
    goback() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='scss' scoped>
.service-article {
  background-color: #F8F8F8;
}
.article {
  background-color: #fff;
  margin-top: -120px;
  position: relative;
  z-index: 20;
}
.hd {
  padding: 32px;
  border-bottom: 1px dashed #666;
  display: flex;
  justify-content: space-between;
  .time {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #536387;
  }
  .goback {
    position: relative;
    cursor: pointer;
    p {
      position: absolute;
      left: 10px;
      top: 0;
      font-size: 14px;
      color: #fff;
      font-family: MicrosoftYaHei;
      line-height: 34px;
    }
  }
}
.bd {
  h2 {
    font-size: 24px;
  font-weight: 700;
  color: #181F2D;
  text-align: center;
  margin: 50px 0 40px;
  }
  .img {
    text-align: center;
    margin-bottom: 38px;
  }
  p {
    color: #515A6E;
    font-size: 14px;
    line-height: 38px;
    margin: 0 68px;
  }
}
.ft {
  .w {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0 68px;
  }
  .prev {
    color: #181F2D;
  }
  .next {
    color: #515A6E;
  }
  .hr {
    border-top: 1px dashed #DCDFE8;
    margin: 10px 0;
  }
  padding-top: 60px;
  padding-bottom: 100px;
  margin-bottom: 100px;
}
.body_html{
  padding: 20px;
}
</style>

<style lang='scss'>
.body_html {
  p {
    line-height: 1.5;
  }
}
</style>
